<script setup lang="ts">
// Types.
//
import { BookingStatus } from '~/apollo/types'
// Properties and events.
//
const props = defineProps<{
  status: BookingStatus
  styleOpts?: string
}>()
// Main variables.
//
const status = toRef(props, 'status')
const styleOpts = toRef(props, 'styleOpts')
</script>

<template>
  <span
    class="booking-status-icon"
    :class="[styleOpts, ` booking-status-${status.toLowerCase()}`]"
  >
    <abbr :title="status">
      <i class="fontello rk-icon" :class="`icon-${status.toLowerCase()}`"></i>
    </abbr>
  </span>
</template>

<style scoped lang="scss">
abbr {
  text-decoration: none;
}

.icon-pending,
.icon-archived {
  color: $base-grey-medium;
}

.icon-cancelled {
  color: $danger;
}

.icon-confirmed {
  color: $base-green;
}

.icon-on_hold {
  color: $warning;
}
</style>
